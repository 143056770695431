import React from "react";
import { Modal } from "react-bootstrap";
import { selectLoader } from "../utils/Helpers";

const ConfirmModal = ({ show, onHide, handleAction,confirmationText, loading ,closeText}) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName=""
        centered
        className="deletModal "
      >
        {/* <Modal.Header closeButton={true}>
        </Modal.Header> */}
        <Modal.Body>
         {confirmationText}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={onHide} className="cancel-btn ">
            No
          </button>
          <button
            onClick={handleAction}
            className="delet-btn"
            style={{ padding: !loading ? "" : "0" }}
          >
            {!loading ? closeText : selectLoader(35)}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;