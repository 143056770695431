import React from "react";
import leftArrow from "../../assets/img/left-arrow.svg";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import "../../assets/css/analytics.css";
const Analytics = () => {
  const navigate = useNavigate();
  const HeaderContent = (
    <>
      <h2>Analytics</h2>
      <div className="head-right">
        <a onClick={() => navigate("/")}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  return (
    <div className="wrapper create_project">
      <div className="main-content">
        <Header content={HeaderContent} />
        <div className="analytics-sec">
             <div className="container">
             <div className="row-cover row">
          <div className="col-lg-6 col-md-6">
          <div className="cardbg-white">
            <div className="card-top-header">
              <label>Project Status</label>
            </div>
           <div className="row project-status-row">
            <div className="col-lg-6 col-md-6">
             <div className="card-boxBg total-projects">
                <p>Total Projects</p>
                <span>20 <sub>(100%)</sub> </span>
             </div>
             </div>
             <div className="col-lg-6 col-md-6">
             <div className="card-boxBg on-time">
                <p>On-time</p>
                <span>12 <sub>(60%)</sub> </span>
             </div>
             </div>
             <div className="col-lg-6 col-md-6">
             <div className="card-boxBg late">
                <p>Late</p>
                <span>06 <sub>(30%)</sub> </span>
             </div>
             </div>
             <div className="col-lg-6 col-md-6"> 
             <div className="card-boxBg early">
                <p>Early</p>
                <span>02 <sub>(10%)</sub> </span>
             </div>
             </div>
            </div> 
          </div>
          </div>
          <div className="col-lg-6 col-md-6">
          <div className="cardbg-white">
            <div className="card-top-header">
              <label>Pending Items</label>
            </div>
           <div className="row project-status-row">
            <div className="col-lg-6 col-md-6">
             <div className="card-boxBg card-box-bodr">
                <p>Change Orders</p>
                <span>34 </span>
             </div>
             </div>
             <div className="col-lg-6 col-md-6">
             <div className="card-boxBg card-box-bodr">
                <p>Requests </p>
                <span>12</span>
             </div>
             </div>
             <div className="col-lg-12 col-md-12">
             <div className="card-boxBg card-box-bodr">
                <p>Client Sign-off</p>
                <span>06</span>
             </div>
             </div>
  
            </div> 
          </div>
          </div>
          <div className="col-lg-6 col-md-6">
          <div className="cardbg-white">
            <div className="card-top-header">
              <label>Completions</label>
              <div className="month-cover">
                <button className="month-btn active">Day</button>
                <button className="month-btn">Week</button>
                <button className="month-btn">Quarter</button>
                <button className="month-btn">Year</button>
              </div>
            </div>
           <div className="row project-status-row">
            <div className="col-lg-6 col-md-6">
             <div className="card-boxBg card-box-bodr">
                <p>Total Projects Completed</p>
                <span>36</span>
             </div>
             </div>
             <div className="col-lg-6 col-md-6">
             <div className="card-boxBg card-box-bodr">
                <p>Total Milestones Completed </p>
                <span>123</span>
             </div>
             </div>
          
  
            </div> 
          </div>
          </div>
        </div>
             </div>
        </div>
      
    
      </div>
    </div>
  );
};

export default Analytics;
