import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Select from "react-select";
import "../../../assets/css/overview-addnew-milestones.css";
import {
  fetchProjectTeamMembers,
  fetchProjectTeamMemberswithNone,
} from "../../../utils/fetchData";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import {
  calculateDateDiff,
  cleanFormattedPricing,
  clearLocalStorage,
  daysOptions,
  formatDate,
  formatOptions,
  formatPricing,
  priceLimit,
  selectLoader,
  userNameLimit,
  validateMilestoneFields,
  validateTextInput,
  validPricing,
} from "../../../utils/Helpers";
import APIServices from "../../../services/APIServices";
import { useNavigate, useParams } from "react-router-dom";

const AddTemplate = ({
  currentTab,
  selectedTemplateId = "",
  setSelectedTemplateId,
  projectDetails,
  companyId = "",
  setMilestoneTab,
  setProjectStatus,
  editProjectDetails,
}) => {
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const { projectId: projectIdFromParams } = useParams();
  const [projectId, setProjectId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [error, setError] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [errors, setErrors] = useState({}); // Track errors for milestones
  const [totalDuration, setTotalDuration] = useState(null);
  const [templateError, setTemplateError] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [initialTemplateName, setInitialTemplateName] = useState("");
  const [initialMilestones, setInitialMilestones] = useState([]);
  const [isUpdateEnable, setUpdateEnable] = useState(true);
  const [milestones, setMilestones] = useState([
    {
      assigneeId: null,
      name: "",
      description: "",
      daysToComplete: null,
      signOffRequired: true,
      signOffTime: "",
      feedbackRequest: true,
      // feedbackRequestTime: "",
      paymentDue: null,
      paymentDueRequired: true,
    },
  ]);
  const [milestonestoDelete, setMilestonesToDelete] = useState([]);

  useEffect(() => {
    if (projectDetails) {
      setProjectId(projectDetails._id);
      setStartDate(
        new Date(projectDetails.startDate).toLocaleDateString("en-CA")
      );
      setEndDate(new Date(projectDetails.endDate).toLocaleDateString("en-CA"));
      fetchProjectTeamMemberswithNone(
        projectDetails._id,
        setAssigneeOptions,
        "user1"
      );
    }
  }, [projectDetails]);

  useEffect(() => {
    if (editProjectDetails) {
      setStartDate(
        new Date(editProjectDetails.startDate).toLocaleDateString("en-CA")
      );
      setEndDate(
        new Date(editProjectDetails.endDate).toLocaleDateString("en-CA")
      );
    }
  }, [editProjectDetails]);

  useEffect(() => {
    if (projectIdFromParams && currentTab == 4) {
      setEditing(true);
      setProjectId(projectIdFromParams);
      fetchProjectTeamMemberswithNone(
        projectIdFromParams,
        setAssigneeOptions,
        "user1"
      );
      fetchMilestones(projectIdFromParams);
    }
  }, [projectIdFromParams, currentTab]);

  useEffect(() => {
    const dateDiff = calculateDateDiff(startDate, endDate);
    setTotalDuration(dateDiff);
    if (selectedTemplateId) {
      setTemplateId(selectedTemplateId);
      fetchTemplateData(selectedTemplateId);
      // setEditing(true);
    }
  }, [selectedTemplateId]);

  const fetchTemplateData = async (templateId) => {
    setPageLoading(true);
    try {
      const response = await APIServices.get(`/template/${templateId}`); // Adjust the URL as necessary
      if (response.milestones) {
        setMilestones(response.milestones);
        setTemplateName(response.name);
        setInitialMilestones(response.milestones);
        setInitialTemplateName(response.name);
        setUpdateEnable(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setPageLoading(false);
    }
  };

  const fetchMilestones = async (projectId) => {
    setPageLoading(true);
    try {
      const response = await APIServices.get(
        `/project-milestone?projectId=${projectId}`
      ); // Adjust the URL as necessary
      if (response.length > 0) {
        setMilestones(response);
      }
      // if (response.milestones) {
      //   setMilestones(response.milestones);
      // }
    } catch (error) {
      setError(error.message);
    } finally {
      setPageLoading(false);
    }
  };

  const checkMilestoneDiff = () => {
    if (
      milestones == initialMilestones &&
      initialTemplateName == templateName
    ) {
      setUpdateEnable(false);
    } else {
      setUpdateEnable(true);
    }
  };

  useEffect(() => {
    checkMilestoneDiff();
  }, [milestones, templateName]);

  const addMilestone = () => {
    if (validateMilestoneFields(milestones, setErrors, true)) {
      setMilestones([
        ...milestones,
        {
          assigneeId: null,
          name: "",
          description: "",
          daysToComplete: "",
          signOffRequired: false,
          signOffTime: "",
          feedbackRequest: false,
          // feedbackRequestTime: "",
          paymentDue: "",
          paymentDueRequired: false,
        },
      ]);
      setTimeout(() => {
        const newIndex = milestones.length;
        inputRefs.current[newIndex]?.focus();
      }, 0);
    }
    saveFormDataToLocalStorage();
  };

  const deleteMilestone = (index, milestoneId = "") => {
    const updatedMilestones = milestones.filter((_, i) => i !== index);
    setMilestones(updatedMilestones);

    if (milestoneId) {
      setMilestonesToDelete([...milestonestoDelete, milestoneId]);
    }
    saveFormDataToLocalStorage();
  };

  const handleInputChange = (index, field, value) => {
    let formattedValue = 0;
    const dateDiff = calculateDateDiff(startDate, endDate);
    if (field === "paymentDue") {
      // Only allow digits and period for decimal values
      value = validPricing(value);
      // Format the number with commas
      formattedValue = formatPricing(value);
      console.log(value);
    }
    setTotalDuration(dateDiff);
    const updatedMilestones = milestones.map((milestone, i) =>
      i === index
        ? {
            ...milestone,
            [field]: field === "paymentDue" ? formattedValue : value,
          }
        : milestone
    );
    setMilestones(updatedMilestones);
    // Clear errors for the current field when it's updated
    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: { ...prevErrors[index], [field]: false },
    }));
    saveFormDataToLocalStorage();
  };

  const backToTemplate = () => {
    setSelectedTemplateId("");
    setMilestoneTab("select");
    setEditing(false);
  };

  const validateName = () => {
    let hasError = false;
    if (!validateTextInput(templateName).isValid && !isEditing) {
      if (!validateTextInput(templateName).cleanedValue) {
        setTemplateError("Please enter Template name.");
      } else {
        setTemplateError(validateTextInput(templateName).message);
      }
      hasError = true;
    }
    return hasError;
  };

  const createProject = async (saveTemplate = false) => {
    if (validateName()) {
      return;
    }
    const dateDiff = calculateDateDiff(startDate, endDate);
    if (
      validateMilestoneFields(milestones, setErrors, saveTemplate, dateDiff)
    ) {
      if (saveTemplate) {
        setLoadingTemplate(true);
      } else {
        setLoading(true);
      }
      let milestoneData = milestones.map((milestone) => {
        const milestoneObj = {
          name: milestone.name,
          description: milestone.description,
          startDate: startDate,
          daysToComplete: Number(milestone.daysToComplete),
          signOffTime: milestone.signOffTime,
          signOffRequired: milestone.signOffRequired,
          // feedbackRequestTime: milestone.feedbackRequestTime,
          feedbackRequest: milestone.feedbackRequest,
          assigneeId: milestone.assigneeId,
          paymentDue: cleanFormattedPricing(milestone.paymentDue),
          paymentDueRequired: milestone.paymentDueRequired,
          projectId: projectId,
        };
        if (isEditing) {
          if (milestone._id) milestoneObj._id = milestone?._id || "";
          if (milestone.status)
            milestoneObj.status = milestone?.status?._id || "";
        }
        return milestoneObj;
      });
      let milestonePayload = {
        milestones: milestoneData,
      };

      let saveTemplatePayload = {
        name: templateName, // Use the template name from state
        companyId: companyId || "",
        milestones: milestones.map((milestone) => ({
          // ...(milestone._id && { _id: milestone._id }),
          name: milestone.name,
          description: milestone.description,
          daysToComplete: Number(milestone.daysToComplete),
          signOffTime: milestone.signOffTime,
          signOffRequired: milestone.signOffRequired,
          // feedbackRequestTime: milestone.feedbackRequestTime,
          feedbackRequest: milestone.feedbackRequest,
          paymentDue: cleanFormattedPricing(milestone.paymentDue),
          paymentDueRequired: milestone.paymentDueRequired,
          // projectId: projectId,
        })),
      };

      try {
        if (saveTemplate) {
          if (!projectIdFromParams) {
            const templateResponse = isEditing
              ? await APIServices.put(
                  `/template/${templateId}`,
                  saveTemplatePayload
                )
              : await APIServices.post("/template", saveTemplatePayload);
            if (templateResponse.message) {
              if (saveTemplate) {
                appToast(templateResponse.message, TOAST_TYPE.SUCCESS);
                setMilestoneTab("select");
              }
            } else {
              appToast(templateResponse.data.message, TOAST_TYPE.ERROR);
            }
          }
        } else {
          const response = isEditing
            ? await APIServices.put(
                `/project-milestone/${projectId}`,
                milestonePayload
              )
            : await APIServices.post("/project-milestone", milestoneData);
          // console.log(response);
          if (response.message) {
            appToast(response.message, TOAST_TYPE.SUCCESS);
            navigate("/");
            setProjectStatus("");
          } else {
            appToast(response.data.message, TOAST_TYPE.ERROR);
          }
        }
      } catch (error) {
        console.error("Form submission error:", error);
      } finally {
        setLoading(false);
        setLoadingTemplate(false);
      }
    } else {
      // Find the first element with the error class
      const firstErrorElement = document.querySelector(".form-invalid");

      if (firstErrorElement) {
        // Scroll to the first error field
        firstErrorElement.scrollIntoView({
          behavior: "smooth", // Smooth scrolling effect
          block: "center", // Scroll so the element is centered in the view
        });

        // Optionally, focus the first error element
        firstErrorElement.focus();
      }
    }
    // Make API call or handle the payload as needed
  };

  //functionalities to get the existing data from local storage.
  const loadFormDataFromLocalStorage = () => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setTemplateName(parsedData.templateName || "");
      setMilestones(parsedData.milestones || []);
    }
  };

  const saveFormDataToLocalStorage = () => {
    const formData = {
      templateName,
      milestones,
    };
    localStorage.setItem("formData", JSON.stringify(formData));
  };

  useEffect(() => {
    window.addEventListener("beforeunload", clearLocalStorage);

    loadFormDataFromLocalStorage();

    // Cleanup event listener
    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, []);

  if (pageLoading) {
    return <div className="nodata-loader"> {selectLoader(100)}</div>;
  }

  return (
    <div className="milestone-add pt-lg-5 mb-3">
      <div className="row">
        <div className="col-lg-7 col-md-12 pb-3">
          <div className="scroll-smooth">
            <h2 className="text-center mb-4">Milestones</h2>
            <div className="card-bg-white">
              {!isEditing && (
                <>
                  <h4 className="text-center mt-sm-4">
                    Write your template name
                  </h4>
                  <div className="add-milestone p-0 mb-4 br-10">
                    <div className="mb-3 form-cover">
                      <input
                        maxLength={userNameLimit}
                        type="text"
                        className={`form-control ${
                          templateError ? "form-invalid" : ""
                        } `}
                        placeholder="Enter Template name"
                        value={templateName}
                        onChange={(e) => {
                          setTemplateName(e.target.value);
                          setTemplateError("");
                        }}
                      />
                      {templateError && (
                        <div className="error-message">{templateError}</div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {milestones.map((milestone, index) => (
                <div key={index}>
                  <h3 className="text-center mb-3 mb-md-5 mt-sm-4">
                    Milestone {index + 1}
                  </h3>
                  <form className="add-milestone p-0 mb-4 br-10">
                    <div className="mb-3 form-cover">
                      <input
                        maxLength={userNameLimit}
                        type="text"
                        className={`form-control ${
                          errors[index]?.name ? "form-invalid" : ""
                        }`}
                        placeholder="Enter milestone name"
                        value={milestone.name}
                        onChange={(e) =>
                          handleInputChange(index, "name", e.target.value)
                        }
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                      {errors[index]?.name && (
                        <div className="error-message">
                          {errors[index]?.name}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 form-cover">
                      <textarea
                        className={`form-control ${
                          errors[index]?.description ? "form-invalid" : ""
                        }`}
                        placeholder="Description"
                        value={milestone.description}
                        onChange={(e) => {
                          handleInputChange(
                            index,
                            "description",
                            e.target.value
                          );
                        }}
                      />
                      {errors[index]?.description && (
                        <div className="error-message">
                          {errors[index]?.description}
                        </div>
                      )}
                    </div>
                    {isEditing &&
                    milestone.status &&
                    milestone?.status?.value != "todo" ? (
                      <div className="disabled-card">
                        <div className="mb-3 form-cover">
                          <div
                            className={`form-group-box-cover ${
                              errors[index]?.daysToComplete
                                ? "form-invalid"
                                : ""
                            }`}
                          >
                            <label className="label-title d-flex align-items-center mb-0 me-3">
                              Days to completion
                            </label>
                            <span>{milestone?.daysToComplete}</span>
                          </div>
                        </div>
                        <div className="mb-3 form-cover">
                          <div
                            className={` form-group-box-cover ${
                              errors[index]?.signOffTime ? "form-invalid" : ""
                            }`}
                          >
                            <div
                              className={`form-check checkbox label-title d-flex align-items-center me-3`}
                            >
                              <input
                                disabled={true}
                                className="form-check-input"
                                type="checkbox"
                                id={`checkboxSignOff${index}`}
                                checked={milestone.signOffRequired}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "signOffRequired",
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                className="form-check-label mb-0"
                                htmlFor={`checkboxSignOff${index}`}
                              >
                                Sign-off required
                              </label>
                            </div>
                            <span>{milestone?.signOffTime} day{milestone?.signOffTime > 1 && "s"}</span>
                          </div>
                        </div>
                        <div className="mb-3 form-cover">
                          <div
                            className={` form-group-box-cover ${
                              errors[index]?.feedbackRequestTime
                                ? "form-invalid"
                                : ""
                            }`}
                          >
                            <div className="form-check checkbox label-title d-flex align-items-center me-3">
                              <input
                                disabled={true}
                                className="form-check-input"
                                type="checkbox"
                                id={`checkboxFeedbackOff${index}`}
                                checked={milestone.feedbackRequest}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "feedbackRequest",
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                className="form-check-label mb-0"
                                htmlFor={`checkboxFeedbackOff${index}`}
                              >
                                Feedback request
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-cover">
                          <div className={` form-group-box-cover `}>
                            <label className="label-title d-flex align-items-center mb-0 me-3">
                              Assignee
                            </label>
                            <span>
                              {milestone?.assigneeId?.fullName ||
                                "Not Selected"}
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 form-cover">
                          <div
                            className={`form-group-box-cover  ${
                              errors[index]?.paymentDue ? "form-invalid" : ""
                            }`}
                          >
                            <div className="form-check checkbox label-title d-flex align-items-center me-3">
                              <input
                                disabled={true}
                                className="form-check-input"
                                type="checkbox"
                                id={`checkboxPayment${index}`}
                                checked={milestone.paymentDueRequired}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "paymentDueRequired",
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                className="form-check-label mb-0"
                                htmlFor={`checkboxPayment${index}`}
                              >
                                Payment Due
                              </label>
                            </div>
                            <div className="form-payment-cover">
                              <span>${milestone.paymentDue}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="">
                          <div className="mb-3 form-cover">
                            <div
                              className={`form-group-box-cover ${
                                errors[index]?.daysToComplete
                                  ? "form-invalid"
                                  : ""
                              }`}
                            >
                              <label className="label-title d-flex align-items-center mb-0 me-3">
                                Days to completion
                              </label>
                              <input
                                type="text"
                                placeholder="Days"
                                className={`form-payment `}
                                value={milestone.daysToComplete}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  const validValue = value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );
                                  // Allow only digits and decimal points
                                  const regex = /^[0-9]*\.?[0-9]*%?$/;

                                  if (regex.test(validValue)) {
                                    handleInputChange(
                                      index,
                                      "daysToComplete",
                                      validValue
                                    );
                                  }
                                }}
                              />
                            </div>
                            {errors[index]?.daysToComplete && (
                              <div className="error-message">
                                {errors[index]?.daysToComplete}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 form-cover">
                            <div
                              className={` form-group-box-cover ${
                                errors[index]?.signOffTime ? "form-invalid" : ""
                              }`}
                            >
                              <div
                                className={`form-check checkbox label-title d-flex align-items-center me-3`}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`checkboxSignOff${index}`}
                                  checked={milestone.signOffRequired}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "signOffRequired",
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label mb-0"
                                  htmlFor={`checkboxSignOff${index}`}
                                >
                                  Sign-off required
                                </label>
                              </div>
                              <input
                                type="text"
                                className="form-payment"
                                placeholder="Days"
                                maxLength={40}
                                value={milestone?.signOffTime || ""}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  const validValue = value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );
                                  // Allow only digits and decimal points
                                  const regex = /^[0-9]*\.?[0-9]*%?$/;
                                  if (regex.test(validValue)) {
                                    handleInputChange(
                                      index,
                                      "signOffTime",
                                      validValue
                                    );
                                  }
                                }}
                              />
                            </div>
                            {errors[index]?.signOffTime && (
                              <div className="error-message">
                                {errors[index]?.signOffTime}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 form-cover">
                            <div
                              className={` form-group-box-cover ${
                                errors[index]?.feedbackRequestTime
                                  ? "form-invalid"
                                  : ""
                              }`}
                            >
                              <div className="form-check checkbox label-title d-flex align-items-center me-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`checkboxFeedbackOff${index}`}
                                  checked={milestone.feedbackRequest}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "feedbackRequest",
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label mb-0"
                                  htmlFor={`checkboxFeedbackOff${index}`}
                                >
                                  Feedback request
                                </label>
                              </div>
                            </div>
                            {errors[index]?.feedbackRequestTime && (
                              <div className="error-message">
                                {errors[index]?.feedbackRequestTime}
                              </div>
                            )}
                          </div>

                          <div className="mb-3 form-cover">
                            <div
                              className={` form-group-box-cover ${
                                errors[index]?.assigneeId ? "form-invalid" : ""
                              }`}
                            >
                              <label className="label-title d-flex align-items-center mb-0 me-3">
                                Assignee
                              </label>
                              <Select
                                options={assigneeOptions}
                                value={assigneeOptions.find(
                                  (option) =>
                                    option.value === milestone.assigneeId ||
                                    option.value === milestone?.assigneeId?._id
                                )}
                                onChange={(option) =>
                                  handleInputChange(
                                    index,
                                    "assigneeId",
                                    option.value
                                  )
                                }
                                placeholder="Select"
                                styles={{
                                  container: (base) => ({
                                    ...base,
                                    maxWidth: "150px",
                                  }),
                                }}
                              />
                            </div>
                            {errors[index]?.assigneeId && (
                              <div className="error-message">
                                {errors[index]?.assigneeId}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 form-cover">
                            <div
                              className={`form-group-box-cover  ${
                                errors[index]?.paymentDue ? "form-invalid" : ""
                              }`}
                            >
                              <div className="form-check checkbox label-title d-flex align-items-center me-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`checkboxPayment${index}`}
                                  checked={milestone.paymentDueRequired}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "paymentDueRequired",
                                      e.target.checked
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label mb-0"
                                  htmlFor={`checkboxPayment${index}`}
                                >
                                  Payment Due
                                </label>
                              </div>
                              <div className="form-payment-cover">
                                <input
                                  type="text"
                                  className="form-payment"
                                  placeholder="$"
                                  maxLength={priceLimit}
                                  value={
                                    milestone?.paymentDue
                                      ? `$ ${milestone.paymentDue}`
                                      : ""
                                  }
                                  onChange={(e) => {
                                    let value = e.target.value;

                                    handleInputChange(
                                      index,
                                      "paymentDue",
                                      value
                                    );
                                  }}
                                />
                                {/* <p>%</p> */}
                              </div>
                            </div>
                            {errors[index]?.paymentDue && (
                              <div className="error-message">
                                {errors[index]?.paymentDue}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end delete-btn">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() =>
                              deleteMilestone(index, milestone?._id)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              ))}

              <div className="d-grid mt-2 mt-md-4 mb-3 mb-sm-4 gap-3">
                <button
                  className="btn addnew-Milestone"
                  type="button"
                  onClick={addMilestone}
                >
                  + Add New Milestone
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-md-12">
          <div className="left-sec-content">
            <h2 className="text-center mb-4">Preview</h2>
            <div className="card-box">
              <div className="inner-card-box">
                <div className="title-content">
                  <h3>
                    {projectDetails.projectName ||
                      editProjectDetails.projectName}
                  </h3>
                </div>
                <div className="time-details">
                  {editProjectDetails ? (
                    editProjectDetails?.latestUpdate[0]?.updateStatus?.value ==
                    "late" ? (
                      <h3 className="progress-delaytext">#Late</h3>
                    ) : editProjectDetails?.latestUpdate[0]?.updateStatus
                        ?.value == "early" ? (
                      <h3 className="progress-earlytext">#Early</h3>
                    ) : (
                      <h3>#On-time</h3>
                    )
                  ) : (
                    <h3>#On-time</h3>
                  )}
                </div>
                <div className="time-details"></div>
              </div>
              <div className="card-bottom">
                <div className="milestone-cover">
                  <h5>{formatDate(startDate)}</h5>
                  <div className="milestone-process"></div>
                  <h5>{formatDate(endDate)}</h5>
                </div>
              </div>
            </div>
            <div className="overview_updated">
              <div className="cardBox-content-item">
                <div className="milestone-edit">
                  <h4>Milestones</h4>
                </div>
                {milestones.map((milestone, index) => (
                  <div key={index}>
                    <div className="design-item">
                      <p>{milestone.name}</p>
                      {milestone.daysToComplete && <p>
                        {milestone.daysToComplete} day
                        {milestone.daysToComplete > 1 && "s"}</p>}
                      
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="buttons-bottom">
              <div className="mt-4 btn-group-milestone">
                <button
                  className="btn-white"
                  type="button"
                  onClick={() => {
                    createProject(true);
                  }}
                  disabled={!isUpdateEnable}
                >
                  {!loadingTemplate ? "Save as Template" : selectLoader(35)}
                </button>
                <button
                  className="btn-black"
                  type="button"
                  onClick={() => {
                    createProject(false);
                  }}
                >
                  {!loading
                    ? isEditing
                      ? "Update Project"
                      : "Create Project"
                    : selectLoader(35)}
                </button>
              </div>
              {selectedTemplateId && (
                <div className="backTobtn">
                  <button
                    className="btn addnew-Milestone"
                    type="button"
                    onClick={backToTemplate}
                  >
                    Back to Templates
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTemplate;
