import React from "react";
import { selectLoader } from "../../../utils/Helpers";
import fileIcon from "../../../assets/img/file-picture.svg";
import deleteBtn from "../../../assets/img/delete.svg";

const UpdatedScope = ({ scopeData, loading }) => {
  // console.log(scopeData);

  return (
    <div className="accordion" id="faq">
      {loading ? (
        <div className="nodata-loader">{selectLoader(65)}</div>
      ) : (
        <>
          {scopeData?.scope?.map((item, index) => (
            <div className="card_cover" key={item._id}>
              <div className="card-header" id={`faqhead${index}`}>
                <a
                  className="btn btn-header-link"
                  data-bs-toggle="collapse" // Update for Bootstrap 5
                  data-bs-target={`#faq${index}`} // Unique ID for each section
                  aria-expanded={false} // Expand the first item by default
                  aria-controls={`faq${index}`}
                >
                  {item.title}
                </a>
              </div>
              <div
                id={`faq${index}`}
                className={`collapse ${index === 0 ? "" : ""}`} // Show the first item by default
                aria-labelledby={`faqhead${index}`}
                data-bs-parent="#faq"
              >
                <div className="card-body">{item.description}</div>
              </div>
            </div>
          ))}

          {scopeData?.attachments?.length > 0 && (
            <div className="card_cover">
              <div className="card-header">
                <a
                  className="btn btn-header-link"
                  data-bs-toggle="collapse" // Update for Bootstrap 5
                  data-bs-target={`#faqAttachment`} // Unique ID for each section
                  aria-expanded={false} // Expand the first item by default
                  aria-controls={`faqAttachment`}
                >
                  Assets
                </a>
              </div>
              <div
                id={`faqAttachment`}
                className={`collapse`} // Show the first item by default
                aria-labelledby={`faqheadAttachment`}
                data-bs-parent="#faq"
              >
                <div className="card-body">
                  <div className="file_uploaded_inner scopeWork">
                    {scopeData.attachments.map((file, index) => (
                      <div className="file_item" key={index}>
                        <a
                          href={file.url}
                          target="_blank"
                          className="attachment-tag"
                        >
                          <div className="img-details">
                            <img src={fileIcon} />
                            <div className="file_name">
                              <h6>{file.name}</h6>
                              <span className="file_size">{file.size}</span>
                            </div>
                          </div>
                          <img
                            src={deleteBtn}
                            alt="comp1"
                            className="deleteBtn cursor_pointer"
                            // onClick={() => handleRemoveFile(index)}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UpdatedScope;
