import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../../assets/css/support.css";
import Select from "react-select";
import { fetchEnumOptions } from "../../../utils/fetchData";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import {
  orgNameLimit,
  selectLoader,
  validateTextInput,
} from "../../../utils/Helpers";

const SupportModal = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    ticketTypeId: null,
    subject: "",
    details: "",
  });
  const [currentTab, setCurrentTab] = useState("Create");
  const [supportTypes, setSupportTypes] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Fetch support types
  const fetchTickets = async (status = "open") => {
    setPageLoading(true);
    try {
      const response = await APIServices.get(`/support?status=${status}`);
      console.log(response);

      if (response.tickets) {
        setTickets(response.tickets);
      }
    } catch (error) {
      console.error("Error fetching status options:", error);
    } finally {
      setPageLoading(false);
    }
  };

  // Fetch support types
  const fetchSupportOptions = async () => {
    try {
      const response = await fetchEnumOptions("supporttickettype");
      if (response.length > 0) {
        const formattedTypes = response.map((option) => ({
          id: option._id,
          value: option._id, // Use _id as value for ticketTypeId
          label: option.name,
        }));
        setSupportTypes(formattedTypes);
      }
    } catch (error) {
      console.error("Error fetching status options:", error);
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    fetchSupportOptions();
  }, []);

  useEffect(() => {
    if (currentTab === "tickets") {
      fetchTickets();
    }
  }, [currentTab]);

  // Handle form field changes
  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));

    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: "",
      });
    }
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};

    if (!formData.ticketTypeId) {
      newErrors.ticketTypeId = "Ticket type is required.";
    }

    // Helper to validate and extract text input validation results
    const validateField = (fieldName, fieldValue, fieldLabel) => {
      const { isValid, cleanedValue, message } = validateTextInput(fieldValue);
      if (!isValid) {
        newErrors[fieldName] = cleanedValue
          ? message
          : `${fieldLabel} is required.`;
      }
    };

    validateField("subject", formData.subject, "Ticket subject");
    validateField("details", formData.details, "Ticket details");

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Payload to submit:", formData);
    if (validateForm()) {
      try {
        setLoading(true);
        const response = await APIServices.post("/support", formData);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          closeModal();
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (err) {
        console.error("Error while verifying email", err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Tabs
      defaultActiveKey="create"
      transition={false}
      id="noanim-tab-example"
      className="support_ticket mt-3"
      onSelect={(key) => {
        setCurrentTab(key);
      }}
    >
      <Tab eventKey="create" title="Create" className="created_ticket">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <Select
                  className={`form-control select2  ${
                    errors.ticketTypeId ? "form-invalid" : ""
                  }`}
                  value={supportTypes.find(
                    (option) => option.value === formData.ticketTypeId
                  )}
                  onChange={(option) =>
                    handleChange("ticketTypeId", option.value)
                  }
                  options={supportTypes}
                  placeholder="Type"
                  isLoading={optionsLoading}
                />
                {errors.ticketTypeId && (
                  <div className="error-message">{errors.ticketTypeId}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <input
                  maxLength={orgNameLimit}
                  type="text"
                  className={`form-control ${
                    errors.subject ? "form-invalid" : ""
                  }`}
                  name="subject"
                  value={formData.subject}
                  onChange={(e) => handleChange("subject", e.target.value)}
                  placeholder="Subject"
                />
                {errors.subject && (
                  <div className="error-message">{errors.subject}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <textarea
                  placeholder="Add details"
                  className={`form-control ${
                    errors.details ? "form-invalid" : ""
                  }`}
                  value={formData.details}
                  onChange={(e) => handleChange("details", e.target.value)}
                ></textarea>
                {errors.details && (
                  <div className="error-message">{errors.details}</div>
                )}
              </div>
            </div>
          </div>

          <button className="btn-black" type="submit">
            {!loading ? "Create" : selectLoader(35)}
          </button>
        </form>
      </Tab>
      <Tab eventKey="tickets" title="Tickets" className="created_ticket">
        {pageLoading ? (
          <div className="nodata-loader"> {selectLoader(60)}</div>
        ) : (
          <div className="row ">
            {tickets.map((ticket) => (
              <div className="col-lg-12 mb-2" key={ticket._id}>
                <div className="new_feature">
                  <small>{ticket.ticketTypeId.name}</small>
                  <h3>{ticket.subject}</h3>
                  <p>{ticket.details}</p>
                  <a href="#">Open</a>
                </div>
              </div>
            ))}
          </div>
        )}
      </Tab>
    </Tabs>
  );
};

export default SupportModal;
