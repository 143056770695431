import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import APIServices from "../../../../services/APIServices";
import { hasPermission, selectLoader } from "../../../../utils/Helpers";
import SignOffForm from "../../forms/SignOffForm";
import SidebarModal from "../../../../components/SidebarModal";
import { useStore } from "../../../../hooks/useStore";
import ChangeOrderModal from "../../updatedOverviewModals/ChangeOrderModal";
import AddQuoteModal from "../../updatedOverviewModals/AddQuoteModal";
import RequestModal from "../../updatedOverviewModals/RequestModal";

const RequestListTab = ({ projectId, currentTab, onRequestClick }) => {
  const activeTabRef = useRef("open"); // Create a ref to store activeTab
  const [store, setStore] = useStore();
  const { user, isUser1, userRole, socket } = useAuth();
  const [isQouteRequired, setQouteRequired] = useState(false);
  const [changeReqId, setChangeReqId] = useState("");
  const [cache, setCache] = useState({ open: null, close: null });
  // const [projectId, setProjectId] = useState("");
  const [activeTab, setActiveTab] = useState("open");
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modals, setModals] = useState({
    signOffModal: false,
    changeOrderModal: false,
    requestModal: false,
  });
  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen,
    }));
  };
  // Fetch requests from the backend
  const fetchRequests = async (status = "open", isloading = true) => {
    try {
      if (cache[status] && isloading) {
        setRequests(cache[status]);
        setLoading(false);
        return;
      }
      setLoading(isloading);
      console.log(projectId);

      const response = await APIServices.get(
        `/project-requests/${projectId}?requestStatus=${status}`
      ); // Replace with your actual API endpoint
      const data = response.data || [];
      setCache((prev) => ({ ...prev, [status]: data }));
      setRequests(data);
    } catch (err) {
      console.error(err);
      setError("Failed to load requests");
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   if (store.projectId) {
  //     setProjectId(store.projectId);
  //   }
  // }, [store.projectId]);

  useEffect(() => {
    if (projectId && currentTab == 2) {
      fetchRequests();
    }
  }, [projectId, currentTab]);

  useEffect(() => {
    if (activeTab && projectId) {
      fetchRequests(activeTab);
    }
  }, [activeTab]);

  const getRequestHeading = (requestType = "") => {
    switch (requestType) {
      case "sign_off":
        return "Milestone Signoff:";
      case "change_order":
        return "Change Order Request";
      case "change_request":
        return "Request";
      default:
        return "";
    }
  };

  const getRequestBadge = (requestType = "") => {
    switch (requestType) {
      case "sign_off":
        return "Sign-Off";
      case "change_order":
        return "Change Order";
      case "change_request":
        return "Request";
      default:
        return "";
    }
  };

  const updateandCloseModal = (modalType) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: false,
    }));
    fetchRequests();
  };

  useEffect(() => {
    activeTabRef.current = activeTab; // Keep the ref updated with the latest activeTab
  }, [activeTab]);

  const handleRequestEvent = (eventName, activity) => {
    if (activeTabRef.current && projectId) {
      fetchRequests(activeTabRef.current, false);
    }
    console.log(eventName, activity);
  };

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on("ProjectRequestCreated", (activity) =>
        handleRequestEvent("ProjectRequestCreated", activity)
      );
      socket.on("ProjectRequestUpdated", (activity) =>
        handleRequestEvent("ProjectRequestUpdated", activity)
      );
      socket.on("ProjectRequestClosed", (activity) =>
        handleRequestEvent("ProjectRequestClosed", activity)
      );
    }
  }, [socket]);

  return (
    <div className="card-body-content request-content">
      {/* Tab Navigation */}
      <div className="bg-white btn-tab-group">
        <button
          className={`btn btn-custom ${
            activeTab === "open" ? "btn-dark" : "btn-light"
          }`}
          onClick={() => setActiveTab("open")}
        >
          Open
        </button>
        <button
          className={`btn btn-custom  ${
            activeTab === "close" ? "btn-dark" : "btn-light"
          }`}
          onClick={() => setActiveTab("close")}
        >
          Closed
        </button>
      </div>

      {/* Display loading, error or requests */}
      {loading && <div>{selectLoader(50)}</div>}
      {error && <p className="error-message">{error}</p>}
      {!loading && !requests.length && <p>No requests found.</p>}
      <div>
        {!loading &&
          requests?.map((request) => (
            <div
              key={request._id}
              className={`add_landing_card ${
                request.unreadMessage > 0 && "unread-card"
              }`}
              onClick={() =>
                onRequestClick(request._id, request.parentRequestId)
              }
            >
              <div className="card_header">
                {request.requestType == "sign_off" ? (
                  <h4>
                    {getRequestHeading(request.requestType)}{" "}
                    {request.milestoneId.name}
                  </h4>
                ) : (
                  <h4>{request.title}</h4>
                )}
                <div className="head_right_btn">
                  <a className="card_btn">
                    {getRequestBadge(request.requestType)}
                    {/* // request.requestType == "change_request" ? "request" : request.requestType.replace("_", " ")} */}
                  </a>
                  {(request.status == "close" ||
                    request.status == "closed") && (
                    <a className={`card_btn button-signed`}>
                      {request.requestType == "change_request" ||
                      request.requestType == "change_order"
                        ? "Closed"
                        : "Signed"}
                    </a>
                  )}
                  {request.unreadMessage > 0 && (
                    <a className={`card_btn tag-unread`}>
                      {request.unreadMessage}
                    </a>
                  )}
                </div>
              </div>
              <div className="card_cantent">
                <p>{request.description}</p>
                <span className="w-100">
                  Requested by {request?.createdBy?.fullName},{" "}
                  {new Date(request.createdDate).toLocaleDateString()}
                </span>
              </div>
            </div>
          ))}
      </div>
      {/* Bottom Actions */}
      <div className="user-chatBox-cover">
        <ul className="chat-btn-list">
          {hasPermission(userRole, "send_signoff_requests") && (
            <li>
              <a
                className="cursor_pointer"
                onClick={() => toggleModal("signOffModal", true)}
              >
                + Sign-Off
              </a>
            </li>
          )}
          {hasPermission(userRole, "change_order") && (
            <li>
              <a
                className="cursor_pointer"
                onClick={() => toggleModal("changeOrderModal", true)}
              >
                + Change Order
              </a>
            </li>
          )}
          {hasPermission(userRole, "change_request") && (
            <li>
              <a
                className="cursor_pointer"
                onClick={() => toggleModal("requestModal", true)}
              >
                + Request
              </a>
            </li>
          )}
        </ul>
      </div>
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.addQuoteModal}
        onHide={() => toggleModal("addQuoteModal", false)}
        headerTitle={"Quote"}
      >
        <AddQuoteModal
          projectId={projectId}
          changeReqId={changeReqId}
          closeModal={() => {
            updateandCloseModal("addQuoteModal");
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.changeOrderModal}
        onHide={() => toggleModal("changeOrderModal", false)}
        headerTitle={"Change Order"}
      >
        <ChangeOrderModal
          projectId={projectId}
          setChangeReqId={setChangeReqId}
          setQouteRequired={setQouteRequired}
          closeModal={() => {
            if (isQouteRequired == true) {
              setModals((prevState) => ({
                ...prevState,
                addQuoteModal: true,
                changeOrderModal: false,
              }));
            } else {
              updateandCloseModal("changeOrderModal");
            }
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.requestModal}
        onHide={() => toggleModal("requestModal", false)}
        headerTitle={"New Request"}
      >
        <RequestModal
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal("requestModal");
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={"edit-profile-modal"}
        show={modals.signOffModal}
        onHide={() => toggleModal("signOffModal", false)}
        headerTitle={"Sign-off Request"}
      >
        <SignOffForm
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal("signOffModal");
          }}
        />
      </SidebarModal>
    </div>
  );
};

export default RequestListTab;
