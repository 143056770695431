import React, { useEffect, useState } from "react";
import axios from "axios"; // Make sure to install axios with `npm install axios`
import APIServices from "../services/APIServices";
import { useNavigate } from "react-router-dom";
import {
  formatDateTime,
  selectLoader,
} from "../utils/Helpers";
import { useAuth } from "../contexts/AuthContext";
import appToast, { TOAST_TYPE } from "../utils/AppToast";

const Notifications = ({ closeModal }) => {
  const navigate = useNavigate();
  const { user, socket } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [userId, setUserId] = useState("");
  const [activeTab, setActiveTab] = useState("all"); // Tabs: all, mentions, requests
  const [loading, setLoading] = useState(true);

  const readNotification = async (id) => {
    try {
      const response = await APIServices.put(`/notification/${id}/read`);
    } catch (error) {
      // Handle errors
      // appToast(error, TOAST_TYPE.ERROR);
    } finally {
      // setLoading(false); // Reset the submitting state
    }
  };

  const clearNotification = async () => {
    // let payload = {
    //   userId: userId,
    // };
    try {
      const response = await APIServices.put(`/notification/clear`, {
        userId,
      });
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        fetchNotifications();
      }
      console.log(response);
    } catch (error) {
      // Handle errors
      appToast(error, TOAST_TYPE.ERROR);
    } finally {
      // setLoading(false); // Reset the submitting state
    }
  };

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on("Notification", (Notification) => {
        setNotifications((prevNotifications) => [
          Notification,
          ...prevNotifications,
        ]);
        // scrollToBottom();
        // console.log("Notification", Notification);
      });
    }
  }, [socket]);

  useEffect(() => {
    fetchNotifications();
    if (user) {
      setUserId(user._id);
    }
  }, []);

  useEffect(() => {
    if (activeTab == "requests") {
      fetchNotifications("request");
    } else if (activeTab == "mentions") {
      fetchNotifications("mentioned");
    } else {
      fetchNotifications("");
    }
  }, [activeTab]);

  const handleUpdateProject = (notificationData) => {
    let notificationDetails = notificationData?.notificationTypeId;
    let requestType = "";
    let projectId = "";
    console.log("notificationData", notificationDetails?.notificationTypeId);
    console.log("projectId", notificationDetails?.projectId);
    console.log("reqId", notificationDetails?._id);
    console.log("requestType", notificationDetails?.requestType);
    if (["activity_message", "mentioned_message"].includes(notificationData.notificationType)) {
      projectId = notificationData?.projectId;
    } else {
      projectId = notificationDetails?.projectId;
    }
    if (
      !notificationDetails?.requestType?.includes("activity") &&
      !notificationDetails?.requestType?.includes("requested") &&
      !notificationDetails?.requestType?.includes("given")
    ) {
      requestType = "request";
    } else if (
      notificationDetails?.requestType?.includes("requested") ||
      notificationDetails?.requestType?.includes("given")
    ) {
      requestType = "feedback";
    }

    readNotification(notificationData?._id);
    navigate("/overview", {
      state: {
        projectId: projectId,
        notificationData: notificationDetails,
        requestType: requestType,
      },
    });
    closeModal();
  };

  // Fetch notifications from the backend
  const fetchNotifications = async (type = "") => {
    setLoading(true);
    try {
      const response = await APIServices.get(
        `/notification?page=1&limit=10&type=${type}`
      ); // Replace with your API endpoint
      setNotifications(response.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const NotificationCard = ({ notification, index }) => {
    const renderRequest = () => (
      <div
        className={`accordion-body ${
          notification.isRead && "accordion-body-bglight"
        }`}
      >
        <p>
          <strong>{notification.createdBy?.fullName}</strong> has
          {["change_request_submitted", "change_order_submitted"].includes(
            notification.notificationType
          ) && " documented "}
          {["change_request_edited", "change_order_edited"].includes(
            notification.notificationType
          ) && " updated "}
          {["change_request_closed", "change_order_close"].includes(
            notification.notificationType
          ) && " closed "}
          a{" "}
          <a
            className="cursor_pointer"
            onClick={() => handleUpdateProject(notification)}
          >
            {notification.notificationTypeId?.requestType.replace("_", " ")}
          </a>
          .
        </p>
        <small>
          {formatDateTime(notification.createdDate).replace("-", "")}
        </small>
      </div>
    );

    const renderFeedback = () => (
      <div
        className={`accordion-body ${
          notification.isRead && "accordion-body-bglight"
        }`}
      >
        <p>
          <strong>{notification.createdBy?.fullName}</strong> has
          {["feedback_requested"].includes(notification.notificationType) &&
            " requested "}
          {["feedback_given"].includes(notification.notificationType) &&
            " provided "}
          <a
            className="cursor_pointer"
            onClick={() => handleUpdateProject(notification)}
          >
            feedback
          </a>
          .
        </p>
        <small>
          {formatDateTime(notification.createdDate).replace("-", "")}
        </small>
      </div>
    );

    const renderSignoff = () => (
      <div
        className={`accordion-body ${
          notification.isRead && "accordion-body-bglight"
        }`}
      >
        <p>
          <strong>{notification.createdBy?.fullName}</strong> has
          {["signoff_submitted"].includes(notification.notificationType) &&
            " documented a "}
          {["signoff_edited"].includes(notification.notificationType) &&
            " updated a "}
          {["signoff_approved"].includes(notification.notificationType) &&
            " signed the "}
          <a
            className="cursor_pointer"
            onClick={() => {
              handleUpdateProject(notification);
            }}
          >
            Sign-off Request
          </a>
          .
        </p>
        <small>
          {formatDateTime(notification.createdDate).replace("-", "")}
        </small>
      </div>
    );

    const renderChatMessage = () => (
      <div
        className={`accordion-body cursor_pointer ${
          notification.isRead && "accordion-body-bglight"
        }`}
        onClick={() => handleUpdateProject(notification)}
      >
        <p dangerouslySetInnerHTML={{ __html: notification.notificationText }}>
          {/* <strong>New Message from {notification.createdBy.fullName}:</strong> has */}
          {/* {notification.notificationText} */}
        </p>
        <small>
          {formatDateTime(notification.createdDate).replace("-", "")}
        </small>
      </div>
    );

    return (
      <div
        key={notification._id}
        className={`tab-pane fade show active accordion-item`}
        id={`notification-${index}`}
        role="tabpanel"
      >
        {/* {["signoff_submitted", "signoff_edited", "signoff_approved"].includes(
        activity.activityType
      ) && renderSignoff()} */}
        {[
          "change_request_submitted",
          "change_request_edited",
          "change_request_closed",
          "change_order_submitted",
          "change_order_close",
          "change_order_edited",
        ].includes(notification.notificationType) && renderRequest()}
        {["feedback_requested", "feedback_given"].includes(
          notification.notificationType
        ) && renderFeedback()}
        {["signoff_submitted", "signoff_edited", "signoff_approved"].includes(
          notification.notificationType
        ) && renderSignoff()}
        {["activity_message", "mentioned_message"].includes(notification.notificationType) &&
          renderChatMessage()}
      </div>
    );
  };

  // if (loading) {
  //   return <div className="nodata-loader">{selectLoader(70)}</div>;
  // }

  return (
    <div className="notification-sidebar">
      <button
        disabled={notifications.length == 0}
        className="clear-notification"
        onClick={clearNotification}
      >
        Clear all
      </button>
      <ul className="nav nav-tabs d-lg-flex" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "all" ? "active" : ""}`}
            onClick={() => setActiveTab("all")}
            type="button"
            role="tab"
            aria-selected={activeTab === "all"}
          >
            All
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "mentions" ? "active" : ""}`}
            onClick={() => setActiveTab("mentions")}
            type="button"
            role="tab"
            aria-selected={activeTab === "mentions"}
          >
            Mentions
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "requests" ? "active" : ""}`}
            onClick={() => setActiveTab("requests")}
            type="button"
            role="tab"
            aria-selected={activeTab === "requests"}
          >
            Requests
          </button>
        </li>
      </ul>
      <div className="tab-content accordion" id="myTabContent">
        {loading ? (
          <div className="nodata-loader">{selectLoader(70)}</div>
        ) : notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <NotificationCard notification={notification} index={index} />

            // <div
            //   key={notification._id}
            //   className={`tab-pane fade show active accordion-item`}
            //   id={`notification-${index}`}
            //   role="tabpanel"
            // >
            //   <div className="accordion-body">
            //     <p>
            //       <strong>{notification.createdBy.fullName}</strong> has
            //       documented a{" "}
            //       <a
            //         className="cursor_pointer"
            //         onClick={() =>
            //           handleUpdateProject(notification.notificationTypeId)
            //         }
            //       >
            //         {notification.notificationTypeId?.requestType.replace(
            //           "_",
            //           " "
            //         ) || notification.notificationType.replace("_", " ")}
            //       </a>
            //       .
            //     </p>
            //     <small>
            //       {formatDateTime(notification.createdDate).replace("-", "")}
            //     </small>
            //   </div>
            // </div>
          ))
        ) : (
          <div className="no-notifications">
            No new notifications at the moment.
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
