import React, { useEffect, useRef, useState } from "react";
import deleteBtn from "../../../assets/img/delete.svg";
import attechFile from "../../../assets/img/ios-attach.svg";
import filePic from "../../../assets/img/file-picture.svg";
import {
  MAX_FILE_SIZE,
  selectLoader,
  userNameLimit,
  validateTextInput,
} from "../../../utils/Helpers";
import APIServices from "../../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import DeleteModal from "../../../components/DeleteModal";
import { useParams } from "react-router-dom";

const ScopeOfWork = ({
  projectDetails,
  currentTab,
  setCurrentTab,
  setCompletedTabs,
}) => {
  // Inside the component
  const inputRefs = useRef([]);
  const { projectId: projectIdFromParams } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [scopeId, setScopeId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [showSkip, setShowSkip] = useState(true);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [sectionId, setSectionId] = useState("");
  const [filesToDelete, setFilesToDelete] = useState([]);

  const [sections, setSections] = useState([
    {
      id: "projectOverview",
      label: "Project Overview",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" }, // Added error state
    },
  ]);

  const [files, setFiles] = useState([]); // State to handle files

  useEffect(() => {
    if (projectDetails) {
      setProjectId(projectDetails._id);
    } else if (projectIdFromParams) {
      setProjectId(projectIdFromParams);
      setEditing(true);
    }
  }, [projectDetails, projectIdFromParams]);

  useEffect(() => {
    if (isEditing && currentTab == 3) {
      fetchScopeDetails();
      setFilesToDelete([]);
    }
  }, [currentTab]);

  // Fetch the scope details from the API
  const fetchScopeDetails = async () => {
    setPageLoading(true);
    try {
      const response = await APIServices.get(
        `/project-scope?projectId=${projectId}`
      );
      if (response.scope) {
        setFiles(response?.attachments);
        let scopeSections = response?.scope.map((scope) => {
          return {
            id: scope?._id,
            label: scope?.title,
            content: scope?.description,
            isEditable: true,
            errors: { label: "", content: "" }, // Added error state
          };
        });
        setScopeId(response?._id);
        setSections(scopeSections);
        // setScopeData(response); // Set the scope data in state
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error("Error fetching scope details:", error);
    } finally {
      setPageLoading(false);
    }
  };

  // Handle text change for content
  const handleTextChange = (e, id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? {
            ...section,
            content: e.target.value,
            errors: { ...section.errors, content: "" },
          }
        : section
    );
    setSections(updatedSections);
  };

  // Handle text change for title (label)
  const handleTitleChange = (e, id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? {
            ...section,
            label: e.target.value,
            errors: { ...section.errors, label: "" },
          }
        : section
    );
    setSections(updatedSections);
  };

  // Toggle edit mode
  const toggleEdit = (id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? { ...section, isEditable: !section.isEditable }
        : section
    );
    setSections(updatedSections);
  };

  // Delete section
  const handleDelete = () => {
    const updatedSections = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSections);
    setDeleteOpen(false);
  };

  // open Delete Modal
  const handleDeleteOpen = (id) => {
    setSectionId(id);
    setDeleteOpen(true);
  };

  // Add new section
  const addNewSection = () => {
    const newSection = {
      id: `newSection${sections.length + 1}`,
      label: ``,
      content: "",
      isEditable: true,
      errors: { label: "", content: "" }, // Initialize error state
    };
    setSections((prevSections) => {
      const updatedSections = [...prevSections, newSection];
      setTimeout(() => {
        const newIndex = updatedSections.length - 1;
        inputRefs.current[newIndex]?.focus();
      }, 0);
      return updatedSections;
    });
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalFiles = files.length + selectedFiles.length;

    // Check if the total number of files exceeds the limit
    if (totalFiles > 5) {
      appToast("You can upload a maximum of 5 files.", TOAST_TYPE.ERROR);
      return;
    }

    // Calculate the total size of all files including the new ones
    const totalSize =
      files.reduce((sum, file) => sum + file.size, 0) +
      selectedFiles.reduce((sum, file) => sum + file.size, 0);

    // Check if the total size exceeds the 40MB limit
    if (totalSize > 40 * 1024 * 1024) {
      appToast(
        "The total size of uploaded files should not exceed 40MB.",
        TOAST_TYPE.ERROR
      );
      return;
    }

    // Add the selected files to the state if they meet the criteria
    setFiles([...files, ...selectedFiles]);
  };

  // Remove file
  const handleRemoveFile = (index) => {
    if (isEditing) {
      let fileToDelete = files.filter((_, i) => i === index);
      if (fileToDelete && fileToDelete.length > 0 && fileToDelete[0]._id) {
        setFilesToDelete((prevFiles) => [...prevFiles, fileToDelete[0]._id]);
      }
    }
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  // Validate inputs
  const validateInputs = () => {
    let isValid = true;
    const updatedSections = sections.map((section) => {
      let labelError = "";
      let contentError = "";

      if (!validateTextInput(section.label).isValid) {
        if (!validateTextInput(section.label).cleanedValue) {
          labelError = "Scope Title is required.";
        } else {
          labelError = validateTextInput(section.label).message;
        }
        isValid = false;
      }
      // if (!section.label.trim()) {
      //   labelError = "Title is required.";
      //   isValid = false;
      // }

      if (!validateTextInput(section.content).isValid) {
        if (!validateTextInput(section.content).cleanedValue) {
          contentError = "Details are required.";
        } else {
          contentError = validateTextInput(section.content).message;
        }
        isValid = false;
      }

      return {
        ...section,
        errors: { label: labelError, content: contentError },
      };
    });

    setSections(updatedSections);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (isSkip = false) => {
    let contentType = {
      "Content-Type": "multipart/form-data",
    };

    if (!isSkip) {
      if (!validateInputs()) {
        // If validation fails, stop the form submission
        return;
      }
    }
    setLoading(true);
    // Create FormData object
    let formData = new FormData();

    // Append projectId
    formData.append("projectId", projectId);

    // Create scope array from sections and append to formData
    const scope = isSkip
      ? []
      : sections.map((section) => ({
          title: section.label,
          description: section.content,
        }));
    formData.append("scope", JSON.stringify(scope));

    // Append files to formData
    files.forEach((file, index) => {
      formData.append(`attachments`, file);
    });

    if (isEditing && filesToDelete.length > 0) {
      formData.append("deletedAttachments", filesToDelete.join(","));
    }
    // TODO: Add your API call to submit the formData
    try {
      const response = isEditing
        ? await APIServices.put(
            `/project-scope/${scopeId}`,
            formData,
            contentType
          )
        : await APIServices.post("/project-scope", formData, contentType);

      if (response.projectId) {
        setScopeId(response._id);
        setCurrentTab(4);
        setCompletedTabs((prev) => ({
          ...prev,
          [4]: true, // Enable the next tab
        }));
        setShowSkip(false);
        setEditing(true);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="scope-of-work mt-5">
      {pageLoading ? (
        <div className="nodata-loader">{selectLoader(70)}</div>
      ) : (
        <form>
          {sections.map((section, index) => (
            <div className="mb-4" key={section.id}>
              <label
                htmlFor={section.id}
                className={`form-label bg-white d-flex  justify-content-between br-10 text-area-label ${
                  section.errors.label ? "form-invalid" : ""
                }`}
              >
                {/* Editable title input field */}
                {section.isEditable ? (
                  <input
                    maxLength={userNameLimit}
                    value={section.label}
                    placeholder="Title"
                    onChange={(e) => handleTitleChange(e, section.id)}
                    className="text-left w-100 label-text-area border-none"
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ) : (
                  <span className={`label-text-area w-100 text-left`}>
                    {section.label}
                  </span>
                )}

                <a
                  className="edit text-right"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleEdit(section.id);
                  }}
                >
                  {section.isEditable ? (
                    <i className="fas fa-save"></i>
                  ) : (
                    <i className="fas fa-pen"></i>
                  )}
                </a>
              </label>
              {/* Error message for title */}
              {section.errors.label && (
                <p className="text-danger">{section.errors.label}</p>
              )}

              {/* Section content textarea */}
              <textarea
                style={{ maxHeight: "245px" }}
                className={`form-control  br-10 mb-2 p-3 ${
                  !section.isEditable ? "input-desable" : ""
                } ${section.errors.content ? "form-invalid" : ""}`}
                id={section.id}
                placeholder="Write Details"
                rows="3"
                value={section.content}
                onChange={(e) => {
                  handleTextChange(e, section.id);
                  e.target.style.height = "auto"; // Reset the height
                  e.target.style.height = `${e.target.scrollHeight + 2}px`; // Set the height to fit content
                }}
                readOnly={!section.isEditable}
              />
              {/* Error message for content */}
              {section.errors.content && (
                <p className="text-danger">{section.errors.content}</p>
              )}

              {index != 0 && (
                <div className="delete-btn">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => handleDeleteOpen(section.id)}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          ))}
          <div className="mb-4">
            <div className="cardbg-form-group">
              <div className="attech-file-cover">
                <label className="attech-btn">
                  <img src={attechFile} alt="comp1" className="attechFile" />
                  Attach Assets
                </label>

                <label className="add-btn-text cursor_pointer">
                  <input
                    accept="image/*"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  + Add
                </label>
              </div>
              {files.map((file, index) => (
                <div className="user-box-cover" key={index}>
                  <div className="username">
                    <img src={filePic} alt="comp1" className="filePic" />
                    <div className="username-content">
                      <p>{file.name}</p>
                      <small>
                        {isEditing
                          ? file.size
                          : `${(file.size / 1024).toFixed(2)} KB`}
                      </small>
                    </div>
                  </div>
                  <img
                    src={deleteBtn}
                    alt="comp1"
                    className="deleteBtn"
                    onClick={() => handleRemoveFile(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="d-grid mt-5 mb-3 gap-3">
            <button
              className="add-new-section"
              type="button"
              onClick={addNewSection}
            >
              + Add new section
            </button>
            <button
              className="btn-black"
              type="button"
              onClick={() => {
                handleSubmit(false);
              }}
            >
              {!loading ? (isEditing ? "Update" : "Next") : selectLoader(35)}
            </button>
          </div>
        </form>
      )}
      {/* {showSkip && (
        <div className="py-4">
          <a
            onClick={() => {
              handleSubmit(true);
            }}
            className="link link-color cursor_pointer"
          >
            Skip
          </a>
        </div>
      )} */}
      <DeleteModal
        show={deleteOpen}
        onHide={() => {
          setDeleteOpen(false);
          setSectionId(""); // Clear the member to delete on cancel
        }}
        handleDelete={handleDelete} // Pass the deletion logic here
        loading={loading}
        itemName={"section"}
      />
    </div>
  );
};

export default ScopeOfWork;
