import React, { useState } from "react";
import leftArrow from "../../assets/img/left-arrow.svg";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import SupportModal from "./homeModals/SupportModal";
import SidebarModal from "../../components/SidebarModal";
import "../../assets/css/support.css";
import mailIcon from "../../assets/img/mail-icon.svg";
import chat from "../../assets/img/chat.svg";
import suportTicket from "../../assets/img/suport-ticket.svg";

const Support = () => {
  const navigate = useNavigate();
  const [modals, setModals] = useState({
    supportModal: false,
  });
  
  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen,
    }));
  };
  const HeaderContent = (
    <>
      <h2>Support</h2>
      <div className="head-right">
        <a onClick={() => navigate("/")}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  return (
    <div className="wrapper support_page">
      <div className="main-content">
        <Header content={HeaderContent} />
        <div className="container">
          <h5>Connect with our team to get the support you need, anytime.</h5>
          <p>Reach out to our team for personalized support.</p>
          <div className="support_card">
            <div className="support_card_cover">
              <div className="support_content">
                <a href="mailto:support@happyending.ai" className="email-link">
                  <img src={mailIcon} alt="comp1" className="mailIcon" />
                  <p>Send us an Email</p>
                </a>
              </div>
              <div
                className="support_content cursor_pointer"
                onClick={() => toggleModal("supportModal", true)}
              >
                <img src={suportTicket} alt="comp1" className="suportTicket" />
                <p>Open a Support Ticket</p>
              </div>
              <div className="support_content">
                <img src={chat} alt="comp1" className="chat" />
                <p>Connect with Chat Support</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Component */}
      <SidebarModal
        extraClass={"edit-profile-modal client_modal"}
        show={modals.supportModal}
        onHide={() => toggleModal("supportModal", false)}
        headerTitle={"Support Ticket"}
      >
        <SupportModal closeModal={() => toggleModal("supportModal", false)} />
      </SidebarModal>
    </div>
  );
};

export default Support;
