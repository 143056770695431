import React, { createContext, useContext, useEffect, useState } from "react";
import { getCookies, getSocketConnected, setCookies } from "../utils/Helpers";
import APIServices from "../services/APIServices";

export const AuthContext = createContext();
const user2 = ["admin", "user"];
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(getCookies("authToken"));
  const [user, setUser] = useState(false);
  const [isUser1, setUser1] = useState(true);
  const [inviterId, setInviterId] = useState("");
  const [userRole, setUserRole] = useState("");
  const [socket, setSocket] = useState(null);

  const fetchUserInfo = async () => {
    if (token) {
      let userRes = await APIServices.get(`/user/detail?token=${token}`);
      if (userRes.status == 404) {
        logout();
      }
      setUser(userRes.data);
      setInviterId(userRes?.data?._id);
      console.log(userRes.data.userType?.role);
      setUserRole(userRes.data.userType?.role);
      if (user2.includes(userRes.data.userType?.role)) {
        setUser1(false);
      }
      return userRes.data;
    }
  };

  useEffect(() => {
    if (user) {
      let newSocket = getSocketConnected(user._id);
      setSocket(newSocket);

      // Listen for connection status
      newSocket.on("connect", () => {
        console.log("Socket connected");
      });

      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
      });
      // // Clean up the socket connection when component unmounts
      // return () => {
      //   newSocket.disconnect();
      // };
    }
  }, [user]);

  useEffect(() => {
    if (!token) return;
    fetchUserInfo();
  }, [token]);

  const logout = () => {
    setUser(null);
    setCookies("authToken", null);
    refreshToken("");
    setUser1(true);
  };

  // If user logoff or login, update token from child component
  const refreshToken = (newToken) => {
    setToken(newToken);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        refreshToken,
        logout,
        token,
        fetchUserInfo,
        inviterId,
        isUser1,
        userRole,
        socket,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
