import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  fetchProjectMilestones,
  fetchProjectTeamMembers,
} from "../../../utils/fetchData";
import { selectLoader, validateTextInput } from "../../../utils/Helpers";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import plusBtn from "../../../assets/img/plus.svg";
import deleteBtn from "../../../assets/img/delete.svg";

const ReqFeedbackModal = ({ projectId, closeModal }) => {
  const [loading, setLoading] = useState(true);
  const [mileStonesList, setMileStonesList] = useState([]);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [description, setDescription] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const [clients, setClients] = useState([{ id: 1, selectedClient: null }]); // Initial client row
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log(projectId);

    if (projectId) {
      fetchMilestones();
      fetchProjectTeamMembers(projectId, setClientOptions, "user2");
    }
  }, [projectId]);

  const fetchMilestones = async () => {
    setLoading(true);
    try {
      let milestones = await fetchProjectMilestones(projectId, "update");
      if (milestones?.length > 0) {
        const formattedPermissions = milestones.map((option) => ({
          value: option?._id,
          label: option?.name,
        }));
        setMileStonesList(formattedPermissions);
      }
    } catch (error) {
      console.error("Error fetching milestones:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!validateTextInput(description).isValid) {
      if (!validateTextInput(description).cleanedValue) {
        newErrors.description = "Please enter description.";
      } else {
        newErrors.description = validateTextInput(description).message;
      }
    }

    if (!selectedMilestone) {
      newErrors.milestone = "Please select a milestone.";
    }

    // Update error handling for clients
    const clientErrors = clients.map((client) =>
      client.selectedClient ? null : "Please select this client."
    );

    if (clientErrors.some((error) => error !== null)) {
      newErrors.clients = clientErrors;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setSubmitLoading(true);
      try {
        const payload = {
          milestoneId: selectedMilestone?.value,
          projectId,
          description,
          requestType: "requested", // Fixed value
          clients: clients.map((client) => client.selectedClient?.value), // Array of client IDs
        };
        const response = await APIServices.post(
          "/project-milestone-feedback",
          payload
        );
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          closeModal();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const addClient = () => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      clients: null,
    }));
    // Prevent adding a new client if the last one is not selected
    if (clients[clients.length - 1]?.selectedClient === null) {
      appToast(
        "Please select the current client before adding a new one.",
        TOAST_TYPE.ERROR
      );
      return;
    }
    setClients((prevClients) => [
      ...prevClients,
      { id: prevClients.length + 1, selectedClient: null },
    ]);
  };

  const removeClient = (id) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      clients: null,
    }));
    setClients((prevClients) =>
      prevClients.filter((client) => client.id !== id)
    );
  };

  const updateClientSelection = (id, selectedOption) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      clients: null,
    }));

    setClients((prevClients) =>
      prevClients.map((client) =>
        client.id === id
          ? { ...client, selectedClient: selectedOption }
          : client
      )
    );
  };

  const getFilteredClientOptions = (currentClientId) => {
    // Exclude already selected clients from options for other rows
    const selectedClientValues = clients
      .filter(
        (client) => client.id !== currentClientId && client.selectedClient
      )
      .map((client) => client.selectedClient.value);

    return clientOptions.filter(
      (option) => !selectedClientValues.includes(option.value)
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="form-group">
              <Select
                className={`form-control select2 ${
                  errors.milestone ? "form-invalid" : ""
                }`}
                options={mileStonesList}
                placeholder="Select Milestones"
                isClearable
                isLoading={loading}
                value={selectedMilestone}
                onChange={(option) => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    milestone: null,
                  }));
                  setSelectedMilestone(option);
                }}
              />
              {errors.milestone && (
                <small className="text-danger">{errors.milestone}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <textarea
                style={{ maxHeight: "320px" }}
                placeholder="Write Request Description"
                className={`form-control ${
                  errors.description ? "form-invalid" : ""
                }`}
                value={description}
                onChange={(e) => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    description: null,
                  }));
                  setDescription(e.target.value);
                  e.target.style.height = "auto"; // Reset the height
                  e.target.style.height = `${e.target.scrollHeight + 2}px`; // Set the height to fit content
                }}
              />
              {errors.description && (
                <small className="text-danger">{errors.description}</small>
              )}
            </div>
          </div>
        </div>
        <div>
          <p className="client-head">Add Client</p>
          {clients.map((client, index) => (
            <div className="row" key={client.id}>
              <div className="col-lg-12">
                <div className="add-client-cover">
                  <div className="form-group client-group">
                    <Select
                      className="form-control"
                      options={getFilteredClientOptions(client.id)}
                      value={client.selectedClient}
                      onChange={(option) =>
                        updateClientSelection(client.id, option)
                      }
                      placeholder="Select Client"
                    />
                  </div>
                  <div className="form-group btn-group client">
                    {clients.length > 1 && (
                      <button
                        type="button"
                        className="delete-btn"
                        onClick={() => removeClient(client.id)}
                      >
                        <img
                          src={deleteBtn}
                          alt="Delete"
                          className="deleteBtn"
                        />
                      </button>
                    )}
                    {index === clients.length - 1 && (
                      <button
                        type="button"
                        className="delete-btn"
                        onClick={addClient}
                      >
                        <img src={plusBtn} alt="Add" className="plusBtn" />
                      </button>
                    )}
                  </div>
                  {errors.clients && errors.clients[index] && (
                    <small className="text-danger">
                      {errors.clients[index]}
                    </small>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <button type="submit" className="btn-black">
          {submitLoading ? selectLoader(35) : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ReqFeedbackModal;
