import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { formatDate } from "../utils/Helpers";

const ProjectProgress = ({ project }) => {
  const progress = project?.progress?.toFixed(0);
  const latestUpdate = project?.latestUpdate[0]?.updateStatus?.value;
  const isLateOrEarly = ["late", "early"].includes(latestUpdate) || false;
  const additionalDays = project?.additionalDays || 0;
  return (
    <div className="processBg-cover">
      <div className="process-content-cover">
        <div className="single-progressbar">
          <div
            className={`process-cover ${
              !isLateOrEarly ? "progress-radius" : ""
            } ${progress < 6 && "black-text"} `}
            id="process-tour"
          >
            {progress >= 1 && (
              <p
                style={{
                  position: "absolute",
                  left: `${
                    progress < 6 ? "4%" : `${(progress / 2 - 3).toFixed(0)}%`
                  }`,
                }}
              >
                {progress}%
              </p>
            )}
            <ProgressBar
              now={progress}
              className={`${(!isLateOrEarly && progress == 100) ? "border-radius" : ""}`}
            />
          </div>
          {additionalDays > 0 && (
            <div
              className={`processBg-gray ${
                isLateOrEarly ? "" : "progress-radius"
              } ${progress > 87 ? "opacity02" : ""} `}
            >
              <p className={`${progress > 95 ? "white-text" : ""}`}>
                +{additionalDays}d
              </p>
            </div>
          )}
        </div>

        <div className={`progress-content`}>
          <p>{formatDate(project?.startDate || new Date())}</p>

          {/* {latestUpdate === "early" ? (
            <p className="text-blue">
              {formatDate(project?.updatedEndDate || new Date())}
            </p>
          ) : ( */}
          <div className="d-flex" style={{ gap: "10px" }}>
            <p>{formatDate(project?.endDate || new Date())}</p>
            {/* )} */}
            {additionalDays > 0 && (
              <p className="text-white">
                {formatDate(project?.additionalDate || new Date())}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="main-process-bodycover2">
        <div className="process-content-cover2">
          {latestUpdate === "late" && (
            <div className="processBg-red progress-radius">
              +{project?.latestUpdate[0]?.daysLate}d
            </div>
          )}
          {latestUpdate === "early" && (
            <div className="processBg-blue progress-radius">
              -{project?.latestUpdate[0]?.daysEarly}d
            </div>
          )}
        </div>
        <div className="progress-content2">
          {latestUpdate === "late" && (
            <p className="text-red">
              {formatDate(project?.updatedEndDate || new Date())}
            </p>
          )}
          {latestUpdate === "early" && (
            <p className="text-blue">
              {formatDate(project?.updatedEndDate || new Date())}
            </p>
          )}
          {/* {latestUpdate === "early" && (
            <p className="text-white">
              {formatDate(project?.endDate || new Date())}
            </p>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ProjectProgress;
