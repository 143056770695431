import React, { useEffect, useState } from "react";
import barcode1 from "../assets/img/barcode1-red.svg";
import barcode2 from "../assets/img/barcode2-red.svg";
import barcode3 from "../assets/img/barcode1-blue.svg";
import barcode4 from "../assets/img/barcode2-blue.svg";
import { Dropdown, ProgressBar } from "react-bootstrap";
import { adjustDate, calculateDateDiff, formatDate } from "../utils/Helpers";
import ProjectProgress from "./ProjectProgress";
const UpdateDaysSlider = ({
  updatedDay = 0,
  projectDetails,
  setUpdatedDay,
}) => {
  const [totalDuration, setTotalDuration] = useState(0);
  const days = Array.from(
    { length: 31 + totalDuration },
    (_, i) => i - totalDuration
  ); // Create days array from -30 to +30
  console.log(days.indexOf(0));
  const [currentDay, setCurrentDay] = useState(days.indexOf(updatedDay)); // Default value set to 0 (index of 0 in days array)

  const visibleCount = 5; // Number of numbers visible at a time (odd for centering)

  const handleIncrease = () => {
    setCurrentDay((prev) => Math.min(prev + 1, days.length - 1));
  };

  const handleDecrease = () => {
    setCurrentDay((prev) => Math.max(prev - 1, 0));
  };

  useEffect(() => {
    if (projectDetails) {
      const dateDiff = calculateDateDiff(
        projectDetails.startDate,
        projectDetails.endDate
      );
      setTotalDuration(dateDiff);
    }
  }, [projectDetails]);

  useEffect(() => {
    if (days) {
      setCurrentDay(days.indexOf(updatedDay));
    }
  }, [totalDuration]);
  // Status label based on the current day
  const getStatusLabel = () => {
    const dayValue = days[currentDay];
    if (dayValue < 0) return "Early";
    if (dayValue === 0) return "On-time";
    if (dayValue > 0) return "Delayed";

    return "Normal";
  };

  const handleValChange = (selectedDay) => {
    // console.log(selectedDay);
    setCurrentDay(days.indexOf(selectedDay));
    console.log(selectedDay);
  };

  useEffect(() => {
    // if (days[currentDay]) {

    if (days[currentDay] == 0 && updatedDay && updatedDay !== 0) {
      console.log(currentDay + updatedDay);

      setCurrentDay((currentDay) => currentDay + updatedDay);
    }

    // }
  }, [updatedDay]);

  useEffect(() => {
    if (days[currentDay] != NaN) {
      let currentDate = days.find((x) => x == currentDay);
      console.log(currentDate);

      setUpdatedDay(days[currentDay]);
    }
  }, [currentDay]);

  // Determine the range of visible numbers centered around the currentDay
  const getVisibleNumbers = () => {
    const halfVisible = Math.floor(visibleCount / 2);
    let start = Math.max(currentDay - halfVisible, 0); // Ensure start is not less than 0
    let end = start + visibleCount;

    // Adjust start and end if the end exceeds the array length
    if (end > days.length) {
      end = days.length;
      start = Math.max(end - visibleCount, 0); // Recalculate start to maintain visibleCount
    }

    return days.slice(start, end); // Get the visible slice
  };

  return (
    <div>
      <div className="row mt-3">
        <div className="col-lg-12">
          <h3 className="project-status-title">Project</h3>

          <div className="project-status">
            <label
              id="status-label"
              className={`status ${getStatusLabel()
                .toLowerCase()
                .replace(" ", "-")}`}
            >
              {getStatusLabel()}
            </label>

            <div className="slider-container">
              <button
                id="decrease-btn"
                className="slider-btn"
                onClick={handleDecrease}
              >
                −
              </button>

              <div className="slider-numbers">
                <div
                  className="numbers-wrapper"
                  style={{
                    transform: `translateX(0px)`, // Center the slider
                    transition: "transform 0.5s ease-in-out",
                  }}
                >
                  {getVisibleNumbers().map((day, index) => (
                    <span
                      onClick={() => {
                        handleValChange(day);
                      }}
                      key={index}
                      className={`number ${getStatusLabel().toLowerCase()} ${
                        days[currentDay] === day ? "active" : ""
                      }`}
                      data-value={day}
                    >
                      {day}
                    </span>
                  ))}
                </div>
              </div>

              <button
                id="increase-btn"
                className="slider-btn"
                onClick={handleIncrease}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-12">
        <div className="progress-update">
          <ProjectProgress project={projectDetails} />
        </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDaysSlider;
