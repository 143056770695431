import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import leftArrow from "../../assets/img/left-arrow.svg";
import Header from "../../components/Header";
import SelectTemplate from "./overviewtabs/SelectTemplate";
import { useAuth } from "../../contexts/AuthContext";

function TemplatesLibrary() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [templateId, setTemplateId] = useState("");
  const [companyId, seCompanyId] = useState("");
  useEffect(() => {
    if (user) {
      seCompanyId(user?.companyId?._id);
    }
  }, [user]);
  const HeaderContent = (
    <>
      <h2>Template Library</h2>
      <div className="head-right">
        <a onClick={() => navigate("/")}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  return (
    <div className="wrapper create_project">
      <div className="main-content">
        <Header content={HeaderContent} />
        <SelectTemplate setTemplateId={setTemplateId} companyId={companyId} isLibrary={true} />
      </div>
    </div>
  );
}

export default TemplatesLibrary;
