import React, { useEffect, useState } from "react";
import "../../../assets/css/overview-addnew-milestones.css";
import "../../../assets/css/overview-select-template.css";
import AddTemplate from "./AddTemplate";
import SelectTemplate from "./SelectTemplate";
import { useAuth } from "../../../contexts/AuthContext";
import { useParams } from "react-router-dom";

const MileStone = ({
  currentTab,
  projectDetails,
  milestoneProgress,
  setMilestoneTab,
  setProjectStatus,
  editProjectDetails,
}) => {
  // State to track which template to render
  const { user } = useAuth();
  const [templateId, setTemplateId] = useState("");
  const [companyId, setCompanyId] = useState("");
  useEffect(() => {
    if (user) {
      setCompanyId(user?.companyId?._id);
    }
  }, [user]);

  useEffect(() => {
    if (milestoneProgress == null) {
      setTemplateId("");
    }
  }, [milestoneProgress]);

  // useEffect(() => {
  //   if (projectIdFromParams && ) {
  //     setTemplateId("");
  //   }
  // }, [projectIdFromParams]);

  return (
    <>
      {!milestoneProgress && (
        <div className="milestone pt-lg-5 mb-3">
          <div className="row justify-content-center mt-100">
            <div className="col-md-6 mb-3">
              <button
                type="button"
                onClick={() => setMilestoneTab("add")}
                className="bg-white btn-custom btn-lg btn-block w-100"
              >
                + Add New
              </button>
            </div>
            <div className="col-md-6 mb-3">
              <button
                type="button"
                onClick={() => setMilestoneTab("select")}
                className="bg-white btn-custom btn-lg btn-block w-100"
              >
                + Select Template
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Conditionally render components based on milestoneProgress */}
      {milestoneProgress === "add" && (
        <AddTemplate
          currentTab={currentTab}
          projectDetails={projectDetails}
          companyId={companyId}
          setMilestoneTab={setMilestoneTab}
          selectedTemplateId={templateId}
          setSelectedTemplateId={setTemplateId}
          editProjectDetails={editProjectDetails}
        />
      )}
      {milestoneProgress === "select" && (
        <SelectTemplate
          setTemplateId={setTemplateId}
          setMilestoneTab={setMilestoneTab}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default MileStone;
