import React from "react";
import loginLogo from "../assets/img/login-logo2.svg";
import logoHover from "../assets/img/login-logohover.svg";
import leftArrow from "../assets/img/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";

const ComingSoon = () => {
  const navigate = useNavigate();
  const HeaderContent = (
    <>
      <div className="head-right">
        <a onClick={() => navigate("/")}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );
  return (
    <div className="wrapper">
      <div className="main-content">
        <Header content={HeaderContent} />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="happy_comingsoon">
                <div className="header-content">
                  <a className="happyLogo-cover">
                    <img
                      src={loginLogo}
                      alt="Happy Ending"
                      className="happy-logo"
                    />
                    <img
                      src={logoHover}
                      alt="Happy Ending"
                      className="happy-logo2"
                    />
                  </a>
                </div>
                <p className="comingsoon-text">coming soon</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
