import React, { useState } from "react";
import uploadIcon from "../../../assets/img/upload2.svg"; // Replace with the actual path
import {
  emailLimit,
  getPlainPhoneNumber,
  selectLoader,
  updateUserDetails,
  userNameLimit,
  validateEmail,
  validateForm,
  validatePhoneNumber,
} from "../../../utils/Helpers";
import { useStore } from "../../../hooks/useStore";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import { useNavigate } from "react-router-dom";
import { ProjectListStrings } from "../strings/ProjectStrings";
import PhoneNumberInput from "../../../components/PhoneInput";

const UpdateProfileModal = ({
  profileUrl,
  formValues,
  setError,
  isVerified,
  setFormValues,
  toggleModal,
  setProfileUrl,
}) => {
  const navigate = useNavigate();
  const [store, setStore] = useStore();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const clearError = (name) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleVerify = async (name) => {
    const plainNumber = getPlainPhoneNumber(formValues.phoneNumber);
    const url = "/send-verification-code";
    const isEmail = name === "email";
    let isValid = false;
    let payload = {};

    const updateErrors = (field, message) => {
      setErrors({
        ...errors,
        [field]: message,
      });
    };

    const handleResponse = (response) => {
      if (response.message) {
        setStore({
          countryDialCode: formValues.countryDialCode,
          phoneNumber: plainNumber,
          userEmail: formValues.email,
          verificationType: name,
        });
        appToast(response.message, TOAST_TYPE.SUCCESS);
        navigate("/verification", {
          state: { isEmail, diableFields: false },
        });
      }
    };

    if (isEmail) {
      const email = formValues.email;
      isValid = validateEmail(email);
      if (!isValid) {
        updateErrors("email", "Please enter a valid email address.");
        return;
      }
      payload = { email };
    } else {
      isValid = validatePhoneNumber(plainNumber);
      if (!isValid) {
        updateErrors("phoneNumber", "PhoneNumber should be 10-15 digits long.");
        return;
      }
      payload = {
        phoneNumber: plainNumber,
        countryDialCode: formValues.countryDialCode,
      };
    }

    const response = await APIServices.post(url, payload);
    handleResponse(response);
  };

  const validate = () => {
    let tempErrors = validateForm(formValues);
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handlePhoneChange = (val, countryDialCode) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      phoneNumber: val,
      countryDialCode: `${countryDialCode}`,
    }));

    clearError("phoneNumber"); // Clear error for phone field
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));

    clearError(name); // Clear error for the current field
  };

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    let imageName = "";
    if (!file) {
      setFormValues({
        ...formValues,
        profilePic: null,
      });
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const { width, height } = img;
      // const isValidSize = width === 1200 && height === 800;

      // setFile(isValidSize ? file : null);
      setProfileUrl(img.src);
      setFormValues({
        ...formValues,
        profilePic: file,
      });
    };
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let plainNumber = getPlainPhoneNumber(formValues.phoneNumber);
      const updatedFormValues = {
        ...formValues,
        phoneNumber: plainNumber,
      };
      let isUpdated = await updateUserDetails(
        updatedFormValues,
        setError,
        setLoading,
        undefined,
        undefined
      );
      if (isUpdated) {
        toggleModal("updateProfile", false);
      }
    }
  };

  return (
    <>
      <div className="usee-deatils">
        {(profileUrl || formValues?.profilePic) && (
          <img
            src={profileUrl || formValues?.profilePic}
            alt={ProjectListStrings.userImageAlt}
            className="user-pic"
          />
        )}
        <input
          accept="image/*"
          type="file"
          id="myFile"
          name="filename"
          onChange={handleFileChange}
        />
        <img
          src={uploadIcon}
          alt={ProjectListStrings.uploadIconAlt}
          className="user-upload"
        />
      </div>
      <div className="profile-deatils">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-group mb-3">
                <input
                  maxLength={userNameLimit}
                  type="text"
                  className={`form-control ${
                    errors.fullName ? "form-invalid" : ""
                  }`}
                  placeholder={ProjectListStrings.fullNamePlaceholder}
                  name="fullName"
                  value={formValues.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && (
                  <span className="error-message">{errors.fullName}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-group mb-3">
                <input
                  maxLength={emailLimit}
                  disabled={false}
                  type="text"
                  className={`form-control ${
                    errors.email ? "form-invalid" : ""
                  }`}
                  name="email"
                  placeholder={ProjectListStrings.emailPlaceholder}
                  value={formValues.email}
                  onChange={handleChange}
                />
                {!isVerified.isEmailVerified && (
                  <p
                    className="veryfy-text"
                    onClick={() => {
                      handleVerify("email");
                    }}
                  >
                    {ProjectListStrings.phoneNumberVerifyText}
                  </p>
                )}
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-group mb-3 phone-number-group">
                <div className="phone-number-group-cover mb-10">
                  <PhoneNumberInput
                    isDisable={false}
                    value={formValues.phoneNumber}
                    onPhoneChange={(val) =>
                      handlePhoneChange(val, formValues.countryDialCode)
                    }
                    countryDialCode={formValues.countryDialCode}
                    countryName={formValues.countryCode}
                    onCountryChange={(country) => {
                      setFormValues({
                        ...formValues,
                        countryDialCode: country.dialCode,
                        countryCode: country.value,
                      });
                      handlePhoneChange(
                        formValues.phoneNumber,
                        country.dialCode
                      );
                    }}
                    errors={errors.phoneNumber}
                  />
                  <div className="d-flex w-100">
                   
                    {errors.phoneNumber && (
                      <span className="error-message">
                        {errors.phoneNumber}
                      </span>
                    )}
                     {!isVerified.isPhoneNumberVerified && (
                      <p
                        className="veryfy-text"
                        onClick={() => {
                          handleVerify("phone");
                        }}
                      >
                        {ProjectListStrings.phoneNumberVerifyText}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={(e) => {
              handleSubmit(e);
            }}
            type="button"
            className="btn-black"
          >
            {!loading ? ProjectListStrings.saveButtonText : selectLoader(35)}
          </button>
        </form>
      </div>
    </>
  );
};

export default UpdateProfileModal;
